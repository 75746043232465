import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { InspectionIcon } from 'client/components/CustomIcons/InspectionsIcon';
import { RoomsIcon } from 'client/components/CustomIcons/RoomsIcon';
import { BuildingsIcon } from 'client/components/CustomIcons/BuildingsIcon';
import { SettingsIcon } from 'client/components/CustomIcons/SettingsIcon';
import { ReportsIcon } from 'client/components/CustomIcons/ReportsIcon';
import { DashboardIcon } from 'client/components/CustomIcons/DashboardIcon';
import { ScenariosIcon } from 'client/components/CustomIcons/ScenariosIcon';
import { UsersIcon } from 'client/components/CustomIcons/UsersIcon';
import { ConsumablesIcon } from 'client/components/CustomIcons/ConsumablesIcon';
import StorefrontIcon from '@mui/icons-material/Storefront';
import PublicIcon from '@mui/icons-material/Public';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import ChecklistIcon from '@mui/icons-material/Checklist';
import { BaseModule } from './base';

export class ModuleShelf extends BaseModule {
  constructor() {
    super({
      name: 'DabbleSHELF',
      shortName: 'SHELF',
      baseRoute: 'shelf',
      icon: ShoppingCartIcon,
      textColor: '#f8f8f8',
      secondaryTextColor: '#fff',
      secondaryColor: '#2d882d',
      backgroundColor: '#040',
      navButtons: [
        {
          type: 'site',
          text: 'Site',
          icon: PublicIcon,
          route: '/shelf/sites',
        },
        {
          type: 'scenario',
          text: 'Scenarios',
          icon: ScenariosIcon,
          route: '/shelf/sites/:site_id/scenarios',
          requiredParams: ['site_id'],
        },
        {
          type: 'nav',
          text: 'Buildings',
          icon: BuildingsIcon,
          route: '/shelf/sites/:site_id/scenarios/:scenario_id/buildings',
          requiredParams: ['site_id', 'scenario_id'],
        },
        {
          type: 'nav',
          text: 'Rooms',
          icon: RoomsIcon,
          route: '/shelf/sites/:site_id/scenarios/:scenario_id/buildings/:bldg_id/spaces',
          requiredParams: ['site_id', 'scenario_id', 'bldg_id'],
        },
        {
          type: 'spacer',
          text: '',
          icon: InspectionIcon,
          route: '',
        },
        {
          type: 'nav',
          text: 'Consumables',
          icon: ConsumablesIcon,
          route: '/shelf/sites/:site_id/scenarios/:scenario_id/consumables',
          requiredParams: ['site_id', 'scenario_id'],
        },
        {
          type: 'nav',
          text: 'Vendors',
          icon: StorefrontIcon,
          route: '/shelf/sites/:site_id/scenarios/:scenario_id/vendors',
          requiredParams: ['site_id', 'scenario_id'],
        },
        {
          type: 'spacer',
          text: '',
          icon: InspectionIcon,
          route: '',
        },
        {
          type: 'nav',
          text: 'Auditors',
          icon: UsersIcon,
          route: '/shelf/sites/:site_id/scenarios/:scenario_id/auditors',
          requiredParams: ['site_id', 'scenario_id'],
        },
        {
          type: 'nav',
          text: 'Audits',
          icon: InspectionIcon,
          route: '/shelf/sites/:site_id/scenarios/:scenario_id/audits',
          requiredParams: ['site_id', 'scenario_id'],
        },
        {
          type: 'nav',
          text: 'Audit Schedules',
          icon: EventRepeatIcon,
          route: '/shelf/sites/:site_id/scenarios/:scenario_id/generators',
          requiredParams: ['site_id', 'scenario_id'],
        },
        {
          type: 'spacer',
          text: '',
          icon: InspectionIcon,
          route: '',
        },
        {
          type: 'nav',
          text: 'Pick Lists',
          icon: ShoppingCartCheckoutIcon,
          route: '/shelf/sites/:site_id/scenarios/:scenario_id/picklists',
          requiredParams: ['site_id', 'scenario_id'],
        },
        {
          type: 'nav',
          text: 'Workflow Runs',
          icon: ChecklistIcon,
          route: '/shelf/sites/:site_id/scenarios/:scenario_id/workflowRuns',
          requiredParams: ['site_id', 'scenario_id'],
        },
        {
          type: 'spacer',
          text: '',
          icon: InspectionIcon,
          route: '',
        },
        {
          type: 'nav',
          text: 'Dashboard',
          icon: DashboardIcon,
          route: '/shelf/sites/:site_id/scenarios/:scenario_id/dashboards',
          requiredParams: ['site_id', 'scenario_id'],
        },
        {
          type: 'nav',
          text: 'Reports',
          icon: ReportsIcon,
          route: '/shelf/sites/:site_id/scenarios/:scenario_id/reports',
          requiredParams: ['site_id', 'scenario_id'],
        },
        {
          type: 'spacer',
          text: '',
          icon: InspectionIcon,
          route: '',
        },
        {
          type: 'nav',
          text: 'Settings',
          icon: SettingsIcon,
          route: '/shelf/sites/:site_id/scenarios/:scenario_id/settings',
          requiredParams: ['site_id', 'scenario_id'],
        },
      ],
    });
  }

  protected setup() {
    return new Promise<void>((resolve, reject) => {
      resolve();
    });
  }
}
